import { useTheme, Text, Card } from 'react-native-paper';
import { MainWrapper, ThemedView } from '../components';
import { NativeStackNavigationProp, createNativeStackNavigator } from '@react-navigation/native-stack';
import { BookIds } from '../enums';
import { DeviceCheck } from './devicecheck';

export const HomePage = ({ navigation }: { navigation: NativeStackNavigationProp<any> }) => {
    const { fonts } = useTheme();
    return (
        <>
            <MainWrapper>
                <ThemedView style={{ padding: 20, flex: 1, flexDirection: "column", overflow: "scroll", alignItems: "center" }}>
                    <Text style={[{ ...fonts.headlineMedium }, { textAlign: "center", marginBottom: 20 }]}>Tryk på en bog for at se om den er kompatibel med din telefon</Text>
                    <Card style={{ margin: 10, height: 296, width: 210 }} elevation={5} onPress={() => { navigation.push("DeviceCheck", { "bookId": BookIds.Firemaster }) }}>
                        <Card.Cover style={{ flexGrow: 1, height: 296, width: 210, backgroundColor: "none" }} source={{ uri: require('../../assets/firemaster.jpg') }} />
                    </Card>
                    <Card style={{ margin: 10, height: 296, width: 210 }} elevation={5} onPress={() => { navigation.push("DeviceCheck", { "bookId": BookIds.ArchmageTower }) }}>
                        <Card.Cover style={{ flexGrow: 1, height: 296, width: 210, backgroundColor: "none" }} source={{ uri: require('../../assets/archmage.jpg') }} />
                    </Card>
                    <Card style={{ margin: 10, height: 296, width: 210 }} elevation={5} onPress={() => { navigation.push("DeviceCheck", { "bookId": BookIds.GloomDragon }) }}>
                        <Card.Cover style={{ flexGrow: 1, height: 296, width: 210, backgroundColor: "none" }} source={{ uri: require('../../assets/gloomdragon.jpg') }} />
                    </Card>
                    <Card style={{ margin: 10, height: 296, width: 210 }} elevation={5} onPress={() => { navigation.push("DeviceCheck", { "bookId": BookIds.WonderSword }) }}>
                        <Card.Cover style={{ flexGrow: 1, height: 296, width: 210, backgroundColor: "none" }} source={{ uri: require('../../assets/wondersword.jpg') }} />
                    </Card>
                    <Card style={{ margin: 10, height: 296, width: 210 }} elevation={5} onPress={() => { navigation.push("DeviceCheck", { "bookId": BookIds.Outtatown }) }}>
                        <Card.Cover style={{ flexGrow: 1, height: 296, width: 210, backgroundColor: "none" }} source={{ uri: require('../../assets/outtatown.jpg') }} />
                    </Card>
                    <Card style={{ margin: 10, height: 296, width: 210 }} elevation={5} onPress={() => { navigation.push("DeviceCheck", { "bookId": BookIds.ColouringBook }) }}>
                        <Card.Cover style={{ flexGrow: 1, height: 296, width: 210, backgroundColor: "none" }} source={{ uri: require('../../assets/colouringbook.jpg') }} />
                    </Card>
                </ThemedView>
            </MainWrapper>
        </>
    )
}