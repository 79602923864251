export enum BookIds {
    Firemaster = 1,
    ArchmageTower = 2,
    GloomDragon = 3,
    WonderSword = 4,
    ColouringBook = 5,
    Outtatown = 6,
}
export enum BookNames {
    Firemaster = "Ildmesteren",
    ArchmageTower = "Ærkemestertårnet",
    GloomDragon = "Dysterdragen",
    WonderSword = "Vidundersværdet",
    ColouringBook = "Malebog",
    Outtatown = "Kludderkongen",
}
export enum BookBundleId {
    Firemaster = "com.Lemuria.Ildmesteren",
    FireMasterIOS = "ildmesteren-smart-book/id1507210465",
    ArchmageTower = "com.Peasoup.ArchmageTower",
    ArchmageTowerIOS = "ærkemestertårnet/id1514859436",
    GloomDragon = "com.Peasoup.Dragon",
    GloomDragonIOS= "dysterdragen/id1560969744",
    WonderSword = "com.Peasoup.Sword",
    WonderSwordIOS = "the-wonder-sword/id1617991212",
    ColouringBook = "com.Peasoup.Malebog",
    ColouringBookIOS = "the-magical-colouring-book/id1667238166",
    Outtatown = "com.Peasoup.Outtatown"
}