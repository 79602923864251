import { useEffect, useState } from "react";
import { MainWrapper, ThemedView } from "../components"
import { Button, Card, Text, useTheme, Avatar, ActivityIndicator } from 'react-native-paper';
import axios from "axios";
import { Image, Platform } from "react-native";
import * as Device from 'expo-device';
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../RootStackParamList";
import { BookBundleId, BookIds, BookNames } from "../enums";
import qs from "qs";
class DeviceResult {
    deviceSupported: boolean;
    deviceInfo?: {
        brand: string;
        device: string;
        manifacturer: string;
        model: string;
    }
}
class UAModel {
    model?: string | null;
    platform?: string | null;
    platformVersion?: string | null;
}
class DeviceRequest {
    model?: string | null;
    os?: string | null;
    osVersion?: string | null;
}
class BundleNames {
    android?: string;
    ios?: string;
}
export const DeviceCheck = ({ navigation, route }: NativeStackScreenProps<RootStackParamList, "DeviceCheck">) => {
    const { bookId } = route.params;
    const [ua, setUa] = useState<any>();
    const { fonts, colors } = useTheme();
    const [deviceResult, setDeviceResult] = useState<DeviceResult>();
    const [loaded, setLoaded] = useState(false);
    const [bookName, setBookName] = useState<string>();
    const [bundleNames, setBundleNames] = useState<BundleNames>();

    useEffect(() => {
        getBookFromId();
        getDeviceData();

    }, [0])
    useEffect(() => {
        if (ua && ua.model && bookName) {
            checkDevice(ua);
        }
    }, [ua, bookName])
    const getDeviceData = async () => {
        var agentData = (navigator as any).userAgentData;
        try {
            var ua = await agentData.getHighEntropyValues(["model", "platform", "platformVersion", "uaFullVersion"]);
            if (!ua || !ua.model) {
                var uModel = new UAModel();
                uModel.model = Device.modelName;
                uModel.platform = Device.osName;
                uModel.platformVersion = Device.osVersion;
                setUa(uModel);
                setLoaded(true);
                setDeviceResult({ deviceSupported: false });
            } else {
                setUa(ua);
            }
        } catch (error) {
            setLoaded(true);
            setDeviceResult({ deviceSupported: false });
        }
    }
    const checkDevice = async (ua: UAModel) => {
        try {
            var data = new DeviceRequest();
            data.model = ua?.model;
            data.os = ua?.platform;
            data.osVersion = ua?.platformVersion;
            var result = await axios.post<DeviceResult>("https://peasoupdevicecheck.azurewebsites.net/api/DeviceCheck?bookName=" + bookName, JSON.stringify(data), { headers: { "Content-Type": "application/json" } });
            if (result && result.data) {
                setDeviceResult({ ...result.data });
            }
        } catch (error) {
            setDeviceResult({ deviceSupported: false });

        } finally {
            setLoaded(true);

        }
    }
    const getBookFromId = () => {
        let bundleNames = new BundleNames();
        switch (Number(bookId)) {
            case BookIds.Firemaster:
                setBookName(BookNames.Firemaster);
                bundleNames.android = BookBundleId.Firemaster;
                bundleNames.ios = BookBundleId.FireMasterIOS;
                setBundleNames(bundleNames);
                break;
            case BookIds.ArchmageTower:
                setBookName(BookNames.ArchmageTower);
                bundleNames.android = BookBundleId.ArchmageTower;
                bundleNames.ios = BookBundleId.ArchmageTowerIOS;
                setBundleNames(bundleNames);
                break;
            case BookIds.GloomDragon:

                setBookName(BookNames.GloomDragon);
                bundleNames.android = BookBundleId.GloomDragon;
                bundleNames.ios = BookBundleId.GloomDragonIOS;
                setBundleNames(bundleNames);
                break;
            case BookIds.WonderSword:
                setBookName(BookNames.WonderSword);
                bundleNames.android = BookBundleId.WonderSword;
                bundleNames.ios = BookBundleId.WonderSwordIOS;
                setBundleNames(bundleNames);
                break;
            case BookIds.ColouringBook:
                setBookName(BookNames.ColouringBook);
                bundleNames.android = BookBundleId.ColouringBook;
                bundleNames.ios = BookBundleId.ColouringBook;
                setBundleNames(bundleNames);
                break;
            case BookIds.Outtatown:
                setBookName(BookNames.Outtatown);
                bundleNames.android = BookBundleId.Outtatown;
                //bundleNames.ios = BookBundleId.OuttatownIOS;
                setBundleNames(bundleNames);
                break;
            default:
                break;
        }
    }
    return (
        <MainWrapper>
            <ThemedView style={{ padding: 20, flex: 1, flexDirection: "column", alignItems: "center" }}>
                <Text style={[{ ...fonts.headlineLarge }, { textAlign: "center", marginBottom: 20 }]}>{bookName}</Text>
                <DeviceSupportedSection deviceResult={deviceResult} ua={ua} loaded={loaded} />
                <DownloadSection ua={ua} bundleNames={bundleNames} />
            </ThemedView>
        </MainWrapper>
    )
}
const DeviceSupportedSection = ({ deviceResult, ua, loaded }: { deviceResult: DeviceResult | undefined, ua: UAModel, loaded: boolean }) => {
    const { fonts, colors } = useTheme();
    if (!loaded) {
        return (
            <Card style={{ width: 300, height: 300 }} contentStyle={{ paddingTop: 10, paddingBottom: 10 }}>
                <Card.Title title={"Din enhed"} titleStyle={[{ ...fonts.headlineLarge }, { textAlign: "center", marginBottom: 20 }]} />
                <Card.Content style={{height: "100%"}}>
                    <ThemedView style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: colors.elevation.level0 }}>
                        <ActivityIndicator animating={true} size={"large"} />
                    </ThemedView>
                </Card.Content>
            </Card>
        )
    }
    return (
        <Card style={{ width: 300, height: 300 }} contentStyle={{ paddingTop: 10, paddingBottom: 10 }}>
            <Card.Title title={"Din enhed"} titleStyle={[{ ...fonts.headlineLarge }, { textAlign: "center", marginBottom: 20 }]} />
            <Card.Content>
                <ThemedView style={{ flex: 1, marginBottom: 5, flexDirection: "row", justifyContent: "space-between", backgroundColor: colors.elevation.level0 }}>
                    <Text style={{ fontWeight: "700" }}>Model</Text>
                    <Text>{
                        deviceResult?.deviceInfo?.model ? deviceResult?.deviceInfo?.model + (ua?.model ? " (" + ua.model + ")" : "")
                            : ua?.model ? ua.model
                                : Device.modelName ? Device.modelName
                                    : "Ikke fundet"}
                    </Text>
                </ThemedView>
                <ThemedView style={{ flex: 1, marginBottom: 5, flexDirection: "row", justifyContent: "space-between", backgroundColor: colors.elevation.level0 }}>
                    <Text style={{ fontWeight: "700" }}>Styresystem</Text>
                    <Text>{ua?.platform ? ua?.platform
                        : Device.osName ? Device.osName
                            : "Ikke fundet"}</Text>
                </ThemedView>
                <ThemedView style={{ flex: 1, marginBottom: 5, flexDirection: "row", justifyContent: "space-between", backgroundColor: colors.elevation.level0 }}>
                    <Text style={{ fontWeight: "700" }}>Version</Text>
                    <Text>{ua?.platformVersion ? ua?.platformVersion : "Ikke fundet"}</Text>
                </ThemedView>
                <ThemedView style={{ flex: 1, marginTop: 10, backgroundColor: colors.elevation.level0, alignItems: "center" }}>
                    {
                        loaded ?
                            deviceResult?.deviceSupported ?
                                <>
                                    <Avatar.Icon style={{ backgroundColor: "green", marginBottom: 10 }} size={50} icon="check" />
                                    <Text style={[{ ...fonts.titleMedium }]}>Din enhed er understøttet</Text>
                                </>
                                :
                                <>
                                    <Avatar.Icon style={{ backgroundColor: "red", marginBottom: 10 }} size={50} icon="exclamation-thick" />
                                    <Text style={[{ ...fonts.titleMedium }]}>Din enhed ikke understøttet</Text>
                                </>
                            : <></>
                    }
                </ThemedView>
            </Card.Content>
        </Card>
    )
}
const DownloadSection = ({ ua, bundleNames }: { ua: UAModel, bundleNames: BundleNames | undefined }) => {
    const { fonts } = useTheme();
    console.log(ua);
    return (
        <Card style={{ width: 300, height: 300, marginTop: 20 }} contentStyle={{ paddingTop: 10, paddingBottom: 10 }}>
            <Card.Title title={"Download"} titleStyle={[{ ...fonts.headlineLarge }, { textAlign: "center", marginBottom: 20 }]} />
            <Card.Content style={{ alignItems: "center" }}>
                <Text style={{ fontWeight: "700" }}>Hent appen til din telefon her</Text>
                {
                    Platform.OS == "android" || ua?.platform == "Android" ?
                        <Button onPress={() => { location.href = "https://play.google.com/store/apps/details?id=" + bundleNames?.android }}>
                            <Image source={{ uri: require('../../assets/google-play-badge.png'), width: 200, height: 80 }} />
                        </Button>
                        : Platform.OS == "ios" || ua?.platform == "Ios" || ua?.platform == "iOS" ?
                            <Button onPress={() => { location.href = "https://apps.apple.com/us/app/" + bundleNames?.ios }}>
                                <Image source={{ uri: require('../../assets/appstore.png'), width: 180, height: 60 }} />
                            </Button>
                            : <>
                                <Button onPress={() => { location.href = "https://play.google.com/store/apps/details?id=" + bundleNames?.android }}>
                                    <Image source={{ uri: require('../../assets/google-play-badge.png'), width: 200, height: 80 }} />
                                </Button>
                                <Button onPress={() => { location.href = "https://apps.apple.com/us/app/" + bundleNames?.ios }}>
                                    <Image source={{ uri: require('../../assets/appstore.png'), width: 180, height: 60 }} />
                                </Button>
                            </>
                }
            </Card.Content>
        </Card>
    )
}